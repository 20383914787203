<template>
  <div class="accountWrap">
    <div class="facilityBox">
      <div class="topbtns">
        <el-button size="medium" @click="$router.go(-1)" icon="el-icon-arrow-left">返回</el-button>
      </div>
      <el-form class="manageForm" :model="params" :inline="true">
        <el-form-item
          label="绑定车牌："
          prop="vehiclePlate"
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="params.VehicleCarNumber"
            placeholder="输入车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票日期：" prop="date">
          <el-date-picker
            v-model="date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading">
        <el-table-column
          type="index"
          align="center"
          fixed="left"
          label="序号"
          width="50"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          fixed="left"
          label="司机姓名"
          width="100"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DriverPhone"
          fixed="left"
          label="司机手机号码"
          width="110"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkUser(scope.row)"
            >
              {{ scope.row.DriverPhone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          fixed="left"
          prop="CarNumber"
          label="车牌号码"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="checkInfo(scope.row)"
            >
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TrailerNumber"
          label="挂车号码"
          width="100"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DriverAllotCount"
          label="司机装货数量"
          width="100"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DriverCarryPrice"
          label="司机运输单价(人民币)"
          width="200"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">￥{{ scope.row.DriverCarryPrice }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="LoadingDatetime"
          label="装货时间"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="LoadNetWeight"
          label="装货净重（吨）"
          width="120"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="SignDatetime"
          label="签收时间"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="UnloadNetWeight"
          label="卸货净重（吨）"
          width="120"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="SignBillID"
          label="签收单号"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="GoodsOwnerName"
          label="货主名称"
          width="180"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="OwnerContactsName"
          label="货主联系人"
          width="120"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="OwnerContactsPhone"
          label="货主联系人手机号码"
          width="200"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="CarrierName"
          label="承运商名称"
          width="200"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="CarrierContactsName"
          label="承运商联系人"
          width="120"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="CarrierContactsPhone"
          label="承运商联系人手机号码"
          width="200"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="CarriagePrice"
          label="承运运输单价(人民币)"
          width="160"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">￥{{ scope.row.CarriagePrice }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsName"
          label="货品名称"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="GoodsUnit"
          label="货品单位"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="AllowLossRate"
          label="允许损耗率(‰)"
          width="120"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="OriginAddress"
          label="起始地"
          width="300"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="DrawnUnit"
          label="提货单位"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DrawnContactsPhone"
          label="提货联系人手机号码"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DrawnAddress"
          label="提货地址"
          width="300"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DrawnContactsName"
          label="提货联系人"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="ArriveAddress"
          label="到达地"
          width="300"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div
              style="color: blue; cursor: pointer"
              @click="openMap(scope.row)"
            >
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TakeGoodsUnit"
          label="收货单位"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="UnloadGoodsContactsPhone"
          label="卸货联系人手机号码"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="UnloadGoodsAddress"
          label="卸货地址"
          width="300"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          l-table-column
          align="center"
          prop="UnloadGoodsContactsName"
          label="卸货联系人"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="HandworkBillID"
          label="手工单号"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="BillDatetime"
          label="单据时间"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="PayeePhone"
          label="收款人手机号码"
          width="160"
         show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="PayeeAmount" label="收款金额" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">￥{{ scope.row.PayeeAmount }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              ￥{{ scope.row.CarriageTotalPrice }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="EntrustedCollectionAmount"
          label="委托代征运费申请金额"
          width="170"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              ￥{{ scope.row.EntrustedCollectionAmount }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background
        class="pagination"
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body class="deep_dialog">
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :list="mapList"
      ></TXmap>
    </el-dialog>
    <!-- 车辆信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.carType" append-to-body class="deep_dialog">
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.carType = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.showDriver" append-to-body class="deep_dialog">
      <span slot="title">司机信息</span>
      <DriverInfo :driverInfo="driverInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.showDriver = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap";
import { tasksInfoDeatils } from "@/api/finance/account/index";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import {
  getDriverDetails,
  getCarInfo,
  getMapPath,
} from "@/api/common/common";
export default {
  data() {
    return {
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 已选中时间
      date: ["", ""],
      // 数据请求参数
      params: {
        pageSize: 10,
        pageIndex: 1,
        VehicleCarNumber: "",
      },
      // 控制弹窗
      flag: {
        // 轨迹回放弹窗
        showMap: false,
        // 车辆信息弹窗
        carType: false,
        // 司机信息弹窗
        showDriver: false,
      },
      // 车辆信息
      vehicleInfo: {},
      // 司机信息
      driverInfo: {},
      // 轨迹列表
      mapList: [],
      loading:true
    };
  },
  created() {
    this.params.InvoiceTaskID = this.$route.query.InvoiceTaskID;
    // 请求数据列表
    this.tasksInfoDeatils();
  },
  methods: {
    // 查看司机信息
    checkUser(item) {
      this.loading = true
      let data = {}
      getDriverDetails({ UserID: item.DriverUserID}).then((res) => {
        data = Object.assign({},res.data.driver_baseInfo,res.data.driver_confirmInfo)
        this.driverInfo = data
        this.flag.showDriver = true;
      }).finally(()=>{
        this.loading = false
      });
    },
    // 查看车辆信息
    checkInfo(item) {
      this.loading = true
      let params = {
        CarNumber:item.CarNumber
      }
      let data = {}
      getCarInfo(params).then(res=>{
        data = Object.assign({},res.data.vehicle_baseInfo,res.data.vehicle_confirmInfo)
        this.vehicleInfo = data
        this.flag.carType = true;
      }).finally(()=>{
        this.loading = false
      });
    },
    //打开地图
    openMap(item) {
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) }).then((res) => {
        this.mapList = res.Track;
        this.flag.showMap = true;
      });
    },
    // 清空搜索条件
    resetForm() {
      this.loading = true;
      this.date = ["", ""];
      this.params.VehicleCarNumber = "";
      this.params.pageIndex = 1;
      this.tasksInfoDeatils();
    },
    // 搜索
    search() {
      this.loading = true;
      this.params.pageIndex = 1;
      this.tasksInfoDeatils();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.params.pageIndex = e;
      this.tasksInfoDeatils();
    },
    // 获取列表数据
    tasksInfoDeatils() {
      let data = this.params;
      data.StartDatetime = this.date[0];
      data.EndDatetime = this.date[1];
      tasksInfoDeatils({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  components: {
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
.facilityBox {
  // margin-top: 30px;
  .topbtns{
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
}
.facilityTop {
  display: flex;
  justify-content: space-between;
}
.deep_dialog{
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer{
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>